import * as React from "react";
import DefautLayout from "../layouts/default";

const AccessibilityPage = () => {
  return (
    <DefautLayout title="Digitale Barrierefreiheit">
      <div className="p-standard content">
        <section id="header">
          <h1 className="py-20">Digitale Barrierefreiheit</h1>
          <h2 className="pt-50 pb-20">Erklärung zur Barrierefreiheit</h2>
          <p>
          Das Bayerische Staatsministerium für Digitales legt großen Wert auf die barrierefreie Nutzbarkeit seines Angebots für alle Bürgerinnen und Bürger sowie Nutzerinnen und Nutzer. Als öffentliche Stelle im Sinne der Richtlinie (EU) 2016/2102 sind wir bemüht, unsere Websites und mobilen Anwendungen im Einklang mit den Bestimmungen des Behindertengleichstellungsgesetzes (BGG) sowie der Barrierefreien-Informationstechnik-Verordnung (BITV 2.0) zur Umsetzung der Richtlinie (EU) 2016/2102 barrierefrei zugänglich zu machen.
          </p>
          <br/>
          <p>
          Rechtsgrundlage für die barrierefreie Gestaltung ist die <a href="https://www.gesetze-bayern.de/Content/Document/BayBITV-2">Bayerische E-Government-Verordnung (BayEGovV)</a> in Verbindung mit der <a href="https://www.bitvtest.de/bitv_test.html">Barrierefreie-Informationstechnik-Verordnung (BITV 2.0)</a>.
          </p>
          <br/>
          <p>Diese Erklärung zur Barrierefreiheit gilt für den Internetauftritt unter www.nextgen4bavaria.de.</p>
        </section>

        <h2 className="pt-50 pb-20">Erstellung dieser Erklärung zur Barrierefreiheit</h2>

        <p>Diese Erklärung wurde am 09.11.2022 erstellt.</p>
        <br/>

        <p>Diese Internetseite wurde von der unabhängigen BITV-Prüfstelle <a href="http://pfennigparade.dieswfactory.com/dienstleistung-sportfolio/digitale-barrierefreiheit">Stiftung Pfennigparade</a> nach der Methode des BITV-Tests durchgeführt. Geprüft wurden die Kriterien der zum Zeitpunkt des Tests aktuellen BITV/WCAG in 92 Schritten. Erforderliche Anpassungen an der Webseite werden im Rahmen der laufenden Weiterentwicklungen vorgenommen.
        </p>
        <br/>
        <h2 className="pt-50 pb-20">Nicht barrierefreie Inhalte</h2>
        <ul>
            <li>Bedienelemente sollten am Beginn der Seite platziert werden</li>
            <li>Schalter zur Kontrastverbesserung hat keinen ausreichenden Kontrast</li>
            <li>Für die Startseite NextGen4Bayern ist keine Strukturierung durch Überschriften vorhanden</li>
            <li>Bedienelemente für Barrierefreiheitsfunktionen sind nicht korrekt als Liste ausgezeichnet</li>
            <li>Grafische Schriften in Infografik ohne ausreichendes Kontrastverhältnis</li>
            <li>Vorder- und Hintergrundfarbe nicht eindeutig festgelegt</li>
            <li>Schriftgrafik wird verwendet</li>
            <li>Horizontaler Scrollbalken</li>
            <li>Beschriftung der Buttons sehr klein und ohne Umbruch</li>
            <li>Button zur Aktivierung der Barrierefreiheitsfunktionen mit zu geringem Kontrastverhältnis (unaktiviert)</li>
            <li>Element für Zoom der Schriftgrafik unter "Davon profitiert Du" fehlerhaft</li>
            <li>Link zum Öffnen des Bewerbungsformulars ist für Nutzer assistiver Systeme nicht aussagekräftig</li>
            <li>Kein alternativer Zugangsweg vorhanden</li>
            <li>Tastaturfokus bei den Bedienelementen für die Barrierefreiheit ist nicht ausreichend</li>
            <li>Im Video ist ein unbeschrifteter Schalter vorhanden</li>
            <li>Inhalte werden bei benutzerdefinierten Einstellungen abgeschnitten oder überlagert</li>
            <li>Keine Informationen in Leichter Sprache vorhanden</li>
            <li>Keine Informationen in Gebärdensprache vorhanden</li>
            <li>Gruppenbeschriftungen sind nicht programmatisch ermittelbar</li>
            <li>Eingabefelder vermitteln nicht den Eingabezweck</li>
            <li>Links brechen nicht um</li>
            <li>Bedienelemente "zur vorherigen Frage navigieren" und "zur nächsten Frage navigieren" sind erst ganz am Ende des Formulars mit der Tastatur bedienbar</li>
            <li>Im Linktext "Powerd by Typefrom" wird das Linkziel nicht angegeben</li>
            <li>Nicht konsistente Verwendung von Überschriften</li>
            <li>Tastaturfokus sollte mit einer anderen Farbe als den OK-Button zu unterscheiden sein.</li>
            <li>Fehlermeldungen werden Screenreader-Nutzer nicht ausgegeben</li>
            <li>Das Element zum Verschieben der individuellen Relevanz ist nicht korrekt implementiert</li>
            <li>Element zum Sortieren zur individuellen Relevanz nicht korrekt implementiert</li>
            <li>Buttons kaum erkennbar</li>
        </ul>

        <h2 className="pt-50 pb-20">Unterstützungen zur Barrierefreiheit</h2>
        <p>Für eine möglichst barrierearme Verwendung der Webseite bieten wir Ihnen aktuell folgende Unterstützungen, die Sie auf der rechten Seite aktivieren können:</p>
        <ul>
            <li>Kontraste erhöhen/verringern: mit dieser Funktion können Sie die Kontraste der Inhalte erhöhen und verringern, um eine bessere Lesbarkeit und Erkennbarkeit sicherzustellen.</li>
            <li>Schrift vergrößern oder verkleinern</li>
        </ul>
        
        <h2 className="pt-50 pb-20">Barrieren melden sowie Informationen zur Barrierefreiheit dieser Website</h2>
        
        <p>Sie möchten uns bestehende Barrieren mitteilen oder Informationen zur Umsetzung der Barrierefreiheit erfragen? Für Ihr Feedback sowie alle weiteren Informationen sprechen Sie unsere verantwortlichen Kontaktpersonen unter nextgen4bavaria(at)unternehmertum.de an.</p>

        <h2 className="pt-50 pb-20">Ansprechpartner für die barrierefreie Zugänglichkeit ist:</h2>

        <p>
            UnternehmerTUM Business Creators<br/>
            Lichtenbergstr. 6<br/>
            85748 Garching bei München<br/>
            E-Mailadresse:{' '}<a href="mailto:nextgen4bavaria@unternehmertum.de">nextgen4bavaria@unternehmertum.de</a>
            
        </p>    


        <h2 className="pt-50 pb-20">Durchsetzungsverfahren</h2>
        <p>Im Rahmen eines Durchsetzungsverfahrens haben Sie die Möglichkeit, online einen Antrag auf Prüfung der Einhaltung der Anforderungen an die Barrierefreiheit zu stellen.</p>
        <br/>
        <a href="https://formularserver.bayern.de/intelliform/assistants/intelliForm-Mandanten/rzsued/Assistants-Dialoge/ldbv/anforderungen_barrierefreiheit/dialog;jsessionid=2llt-K5u_run-5J6emtxUxc-EeliE3UoR63sHJVD.IF1?state=5cf05ab73ca4d60&cc=YtDRdXtzUOv6ufqF3nJY8TnhRQjx3Ts-4Sj7NyeL-0">Antrag auf Prüfung der Anforderungen an die Barrierefreiheit gem. § 3 BayEGovV</a>

        <h3 className="py-10">Kontaktdaten der Durchsetzungsstelle:</h3>

        <p>   
            Landesamt für Digitalisierung, Breitband und Vermessung
            <br/>
            IT-Dienstleistungszentrum des Freistaats Bayern
            <br/>
            Durchsetzungs- und Überwachungsstelle für barrierefreie Informationstechnik
            <br/>
            St.-Martin-Straße 47
            <br/>
            81541 München
            <br/>
            E-Mail: <a href="mailto:bitv@bayern.de">bitv@bayern.de</a>
            <br/>
            <a href="https://www.ldbv.bayern.de/digitalisierung/bitv.html">https://www.ldbv.bayern.de/digitalisierung/bitv.html</a>
        </p>

        <h2 className="pt-50 pb-20">Schlichtungsverfahren</h2>
        <p>Wenn auch nach Ihrem Feedback an den oben genannten Kontakt keine zufriedenstellende Lösung gefunden wurde, können Sie sich an die Schlichtungsstelle nach § 16 Behindertengleichstellungsgesetz (BGG) wenden. Die Schlichtungsstelle BGG hat die Aufgabe, bei Konflikten zum Thema Barrierefreiheit zwischen Menschen mit Behinderungen und öffentlichen Stellen des Bundes eine außergerichtliche Streitbeilegung zu unterstützen. Das Schlichtungsverfahren ist kostenlos. Es muss kein Rechtsbeistand eingeschaltet werden. Weitere Informationen zum Schlichtungsverfahren und den Möglichkeiten der Antragstellung erhalten Sie unter: <a href="www.schlichtungsstelle-bgg.de">www.schlichtungsstelle-bgg.de</a>.</p>
        <br/>
        <p>Direkt kontaktieren können Sie die Schlichtungsstelle BGG unter <a href="mailto:info@schlichtungsstelle-bgg.de">info@schlichtungsstelle-bgg.de</a>.</p>
      </div>
    </DefautLayout>
  )}

  export default AccessibilityPage;